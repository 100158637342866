<template>
  <div>
  <v-card outlined rounded :loading="loading">
    <v-card-text class="pa-10">
      <div class="d-flex justify-space-between align-center">
        <h2>{{ $t("Account type") }}</h2>
        <help-center-link topic="account-type" link-text="Kako popuniti?"/>
      </div>
      <p class="grey--text text-body-1 text--grey darken-1 mb-6 mt-2">
        {{ $t("Account type description") }}
      </p>
      <v-row>
        <v-col cols="12" md="12">

      <v-radio-group v-model="form.account_type">
        <v-radio :value="$CONFIG.USER.ACCOUNT_TYPE.INDIVIDUAL" class="mb-5">
          <template v-slot:label>
            <div>
              <strong>{{ $t("Account type Individual") }}</strong>
              <br />
              <small>
                {{ $t("Account type Individual description") }}
              </small>
            </div>
          </template>
        </v-radio>
        <v-radio :value="$CONFIG.USER.ACCOUNT_TYPE.BUSINESS">
          <template v-slot:label>
            <div>
              <strong>{{ $t("Account type Business") }}</strong>
              <br />
              <small>{{ $t("Account type Business description") }}</small>
            </div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-text-field
        v-if="form.account_type === $CONFIG.USER.ACCOUNT_TYPE.INDIVIDUAL"
        v-model="form.jmbg"
        :error-messages="firstError('form.jmbg')"
        type="text"
        class="mt-2 hide-spin-button"
        outlined
        :label="$t('JMBG')"
        :hint="$t('JMBG hint')"
        persistent-hint
        maxlength="13"
        counter
        min="1"
      />

      <v-text-field
        v-if="form.account_type === $CONFIG.USER.ACCOUNT_TYPE.BUSINESS"
        v-model="form.pib"
        :error-messages="firstError('form.pib')"
        type="number"
        class="mt-2 hide-spin-button"
        outlined
        :label="$t('PIB')"
        :hint="$t('PIB hint')"
        persistent-hint
        maxlength="9"
        counter
        min="1"
      />
      <v-text-field
        v-if="form.account_type === $CONFIG.USER.ACCOUNT_TYPE.BUSINESS"
        v-model="form.mb"
        :error-messages="firstError('form.mb')"
        type="number"
        class="mt-2 hide-spin-button"
        outlined
        :label="$t('MB')"
        :placeholder="$t('MB')"
        :hint="$t('MB hint')"
        persistent-hint
        maxlength="8"
        counter
        min="1"
      />
      <v-text-field
        v-if="form.account_type === $CONFIG.USER.ACCOUNT_TYPE.BUSINESS"
        v-model="form.company_name"
        :error-messages="firstError('form.company_name')"
        type="text"
        class="mt-2"
        outlined
        :label="$t('Business name')"
        :placeholder="$t('Business name')"
        :hint="$t('Business name hint')"
        persistent-hint
        min="1"
      />

      <v-text-field
        v-model="form.city"
        :error-messages="firstError('form.city')"
        type="text"
        class="mt-2"
        outlined
        :label="$t('City')"
        :hint="$t('City hint')"
        persistent-hint
        min="1"
      />
      <v-text-field
        v-model="form.address"
        :error-messages="firstError('form.address')"
        type="text"
        class="mt-2"
        outlined
        :label="$t('Street and number')"
        :placeholder="$t('Street and number hint')"
        :hint="$t('Street and number hint')"
        persistent-hint
        min="1"
      />
      </v-col>
      <!-- <v-col cols="12" md="4">
        <info
          class="mt-15"
          title="Infos.Payout.title"
          description="Infos.Payout.description"
          icon="green-energy"
        />
      </v-col> -->
      </v-row>
    </v-card-text>
    <v-divider />
    <CardActions @submit="submit" v-if="viewAsAdmin === false"/>
  </v-card>

  <v-card outlined rounded :loading="loading" class="mt-10">
    <v-card-text class="pa-10">

      <v-row v-if="methods">
        <v-col cols="12" md="12">
          <div class="d-flex justify-space-between align-center">
            <h2>{{ $t("Payout options") }}</h2>
            <help-center-link topic="payment-method" link-text="Kako popuniti?"/>
          </div>
          <p class="grey--text text-body-1 text--grey darken-1 mb-6 mt-2">
            {{ $t("Payout options description") }}
          </p>
          <div v-for="(method, methodConst, index) in methods" :key="index"
            :class="{'error--text': firstError(methodConst) && firstError(methodConst)[0]}">
            <div v-if="form.account_type &&
            method.allowed_account_types.includes(form.account_type)">
              <div class='pa-3 py-5' >
                <div class="d-flex align-center justify-space-between">
                  <div class="subtitle-1 font-weight-bold">{{$t(method.name)}}</div>
                  <div v-if="viewAsAdmin === false" class="d-flex align-center">
                    <span
                      @click.stop="validateIfUserCanEditPayout(method)"
                      class="ml-2 cursor-pointer text-decoration-underline text--link"
                      >{{ hasPaymentMethod(methodConst) ? $t('Edit') : $t('Add')}}</span>
                      <confirm
                        v-if="hasPaymentMethod(methodConst)"
                        @save="validateIfUserCanDeletePayout(methodConst)"
                      />
                  </div>
                </div>
                <div class="grey--text">
                    <div v-if="hasPaymentMethod(methodConst)">
                      <v-icon
                        class="cursor-pointer  "
                        color="green"
                      >
                        mdi-check
                      </v-icon>
                      {{addedPaymentMethodText(methodConst)}}
                    </div>
                    <div v-else>
                      {{$t('None selected')}}
                    </div>
                </div>
              </div>

              <modal
                  v-if="deletePayoutValidationErrors"
                  :show="true"
                  @close="deletePayoutValidationErrors = undefined"
                  hide-actions
                  :title="$t('It is not possible to delete or modify', { payoutName: $t(deletePayoutValidationErrors.payoutName) })"
                  max-width="810"
                >
                <v-container>
                  <v-row>
                    <v-col cols="12" >
                      <div class="justify-center w-100 mt-2 px-2 mx-2 mb-5">
                        <b>{{ $t('You cannot delete or modify this payout method') }}.</b>
                        <br/>
                        <br/>
                        <div v-if="deletePayoutValidationErrors.reservations && deletePayoutValidationErrors.reservations.length">
                          <span v-html="$t('Payout method is used for', { payoutName: $t(deletePayoutValidationErrors.payoutName) })"></span>
                          {{ $tc('reservations_count', deletePayoutValidationErrors.reservations.length) }}:
                          <table class="table mb-0" style="width:100%; text-align:left;">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{{ $t('Booking reference') }}</th>
                                <th>{{ $t('Details') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(reservation,index) in deletePayoutValidationErrors.reservations" :key="reservation.id">
                                <td>{{index + 1}}</td>
                                <td>{{reservation.booking_reference}}</td>
                                <td>
                                  <v-btn
                                    class="text-decoration-underline text-capitalize cursor-pointer mr-2"
                                    @click.stop="loadReservationPreview(reservation.id)"
                                  >{{ $t('Details') }}</v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br>
                        <div v-if="deletePayoutValidationErrors.inquries && deletePayoutValidationErrors.inquries.length">
                          <span v-html="$t('Payout method is used for', { payoutName: $t(deletePayoutValidationErrors.payoutName) })"></span>
                          {{ $tc('inquiries_count', deletePayoutValidationErrors.inquries.length) }}:
                          <table class="v-data-table mb-0" style="width:100%; text-align:left;">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>{{ $t('Inquiry reference') }}</th>
                                <th>{{ $t('Details') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(inquiry,index) in deletePayoutValidationErrors.inquries" :key="inquiry.id">
                                <td>{{index + 1}}</td>
                                <td>{{inquiry.booking_reference}}</td>
                                <td>
                                  <v-btn
                                    class="text-decoration-underline  text-capitalize cursor-pointer mr-2"
                                    @click.stop="loadInquiryPreview(inquiry.id)">{{ $t('Details') }}</v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <br/>
                        <b>{{ $t('if_you_want_to_change_payout_method', { email: 'saradnja@bookaweb.com' }) }}.</b>
                      </div>
                  </v-col>
                    </v-row>
                  </v-container>
                </modal>

              <v-divider/>
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="12" md="4">
          <info
            class="mt-15"
            title="Infos.Payout Methods.title"
            description="Infos.Payout Methods.description"
            icon="green-energy"
          />
      </v-col> -->
      </v-row>

    <component
      v-if="payment_method"
      :is="payment_method.component"
      @close="closePaymentMethod"
      @submit="paymentSubmited"
      :data="paymentComponentData"
      :account-type="form.account_type"
    />
    </v-card-text>
  </v-card>

  <reservation-preview-modal/>
  <inqury-preview-modal/>

  </div>

</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import axios from 'axios';
import Info from '@/components/Info.vue';
import FormValidationMixin from '@/lib/FormValidationMixin';
import ViewAsAdminMixin from '@/lib/ViewAsAdminMixin';
import Confirm from '@/components/Confirm.vue';
import CardActions from '@/components/CardActions.vue';
import HelpCenterLink from '@/components/HelpCenterLink.vue';

import SerbiaRsd from '@/components/payout-methods/SerbiaRsd.vue';
import Iban from '@/components/payout-methods/Iban.vue';
import WesternUnion from '@/components/payout-methods/WesternUnion.vue';

import Modal from '@/components/Modal.vue';
import ReservationPreviewModal from '@/components/reservations/ReservationPreviewModal.vue';
import InquryPreviewModal from '@/components/inquries/InquryPreviewModal.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, ViewAsAdminMixin],
  components: {
    Info,
    Confirm,
    CardActions,
    // Payment Methods below
    SerbiaRsd,
    Iban,
    WesternUnion,
    HelpCenterLink,
    Modal,
    ReservationPreviewModal,
    InquryPreviewModal,
  },
  validations: {
    form: {
      account_type: {
        required,
      },
      jmbg: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return this.form.account_type === this.$CONFIG.USER.ACCOUNT_TYPE.INDIVIDUAL;
        }),
      },
      pib: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return this.form.account_type === this.$CONFIG.USER.ACCOUNT_TYPE.BUSINESS;
        }),
      },
      mb: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return this.form.account_type === this.$CONFIG.USER.ACCOUNT_TYPE.BUSINESS;
        }),
      },
      company_name: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return this.form.account_type === this.$CONFIG.USER.ACCOUNT_TYPE.BUSINESS;
        }),
      },
      city: {
        required,
      },
      address: {
        required,
      },
    },
  },
  data() {
    return {
      form: {
        account_type: this.$CONFIG.USER.ACCOUNT_TYPE.INDIVIDUAL,
        jmbg: null,
        pib: null,
        mb: null,
        company_name: null,
        city: null,
        address: null,
      },
      payment_method: undefined,
      methods: undefined,
      payouts: [],

      deletePayoutValidationErrors: undefined,
    };
  },
  mounted() {
    this.load();
    this.loadPayouts();
  },
  computed: {
    ...mapGetters(['loading']),
    paymentComponentData() {
      if (!this.payment_method) {
        return undefined;
      }
      const payout = this.payouts.find((x) => x.type === this.payment_method.const);
      if (payout && payout.data) {
        return payout.data;
      }
      return undefined;
    },
  },
  methods: {
    load() {
      this.$store.dispatch('loading', true);
      axios
        .get(`user-settings/detail${this.viewAsAdmin ? `?viewAsAdmin=${this.viewAsAdmin}` : ''}`)
        .then(({ data }) => {
          if (data) {
            if (data.detail) {
              this.form.account_type = data.detail.account_type;
              this.form.jmbg = data.detail.jmbg;
              this.form.pib = data.detail.pib;
              this.form.mb = data.detail.mb;
              this.form.company_name = data.detail.company_name;
              this.form.city = data.detail.city;
              this.form.address = data.detail.address;
            }
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    loadPayouts() {
      this.$store.dispatch('loading', true);
      axios
        .get(`user-settings/payout-method${this.viewAsAdmin ? `?viewAsAdmin=${this.viewAsAdmin}` : ''}`)
        .then(({ data }) => {
          if (data) {
            if (data.methods) {
              this.methods = data.methods;
            }
            if (data.payouts) { // this does not work
              this.payouts = data.payouts;
            }
          }
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('user-settings/detail', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch(
            'message',
            this.$t('Noti.Payout'),
          );
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    paymentModal(method) {
      this.payment_method = method;
    },
    closePaymentMethod() {
      this.payment_method = undefined;
    },
    hasPaymentMethod(type) {
      return this.payouts.find((x) => x.type === type);
    },
    paymentSubmited() {
      this.closePaymentMethod();
      this.$store.dispatch(
        'message',
        this.$t('Noti.Payout Methods'),
      );
      this.loadPayouts();
    },
    validateIfUserCanDeletePayout(methodConst) {
      axios.post('user-settings/payout-method/validate', {
        type: methodConst,
      }).then(({ data }) => {
        if (data.can_delete) {
          this.deletePaymentMethod(methodConst);
        } else {
          // show modal with validation errors
          this.deletePayoutValidationErrors = data;
        }
      });
    },
    validateIfUserCanEditPayout(method) {
      if (!this.hasPaymentMethod(method.const)) {
        this.paymentModal(method);
        return;
      }
      axios.post('user-settings/payout-method/validate', {
        type: method.const,
      }).then(({ data }) => {
        if (data.can_delete) {
          this.paymentModal(method);
        } else {
          // show modal with validation errors
          this.deletePayoutValidationErrors = data;
        }
      });
    },
    deletePaymentMethod(methodConst) {
      this.$store.dispatch('loading', true);
      axios
        .delete('user-settings/payout-method', {
          data: {
            type: methodConst,
          },
        })
        .then(() => {
          this.$store.dispatch(
            'message',
            this.$t('Noti.Payout Methods Deleted'),
          );
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
          this.loadPayouts();
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    addedPaymentMethodText(type) {
      const payment = this.payouts.find((x) => x.type === type);
      let string = '';
      if (payment && payment.data) {
        if (payment.data.full_name) {
          string += payment.data.full_name;
        }
        if (payment.data.account_number) {
          string += `, ${payment.data.account_number}`;
        }
        if (payment.data.bank_name) {
          string += `, ${payment.data.bank_name}`;
        }
        if (payment.data.phone) {
          string += `, ${payment.data.phone}`;
        }
      }
      return string;
    },
    loadReservationPreview(id) {
      this.$store.dispatch('setReservationPreview', id);
    },
    loadInquiryPreview(id) {
      this.$store.dispatch('setInquiryPreview', id);
    },
  },
};
</script>
